// 一番下が最新
export const NewsReleaseObjects =
  [
    {
      id: 1,
      post_date: "2024.07.02",
      title: "会社設立及びホームページ開設のご挨拶",
      content:
        <div>
          <p>平素は格別のお引き立てを賜り厚く御礼申し上げます。</p>
          <br />
          <p>この度、株式会社 F Tech Japanを設立いたしました。</p>
          <p>また、会社設立に合わせまして、弊社ホームページを開設いたしました。</p>
          <br />
          <p>お客様のご期待にお応えできるよう日々精進してまいりますので、</p>
          <p>今後ともご厚情を賜りますようお願い申し上げます。</p>
          <br />
          <br />

          株式会社 F Tech Japan(エフテックジャパン)
        </div>
    },
  ]