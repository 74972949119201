import { useState } from 'react';
import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { NewsReleaseObjects } from '../../data/NewsRelease'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Pagination } from '../../Component/Pagination'
import { Helmet } from "react-helmet-async";
import { Breadcrumb } from '../../Component/Breadcrumb';
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';

export const NewsRelease = () => {
  const reversedNewsReleaseObjects = [...NewsReleaseObjects].reverse();
  const itemsPerPage = 5
  const [itemsOffset, setItemsOffset] = useState(0)
  const endOffset = itemsOffset + itemsPerPage
  const currentProps = reversedNewsReleaseObjects.slice(itemsOffset, endOffset)
  const pageCount = Math.ceil(reversedNewsReleaseObjects.length / itemsPerPage)
  const handlePageClick = (e) => {
    const newOffSet = (e.selected * itemsPerPage) % reversedNewsReleaseObjects.length
    setItemsOffset(newOffSet)
  }

  return (
    <>
      <Helmet>
        <title>ニュースリリース | 最新情報とお知らせ | 株式会社F Tech Japan</title>
        <meta name="description" content="株式会社F Tech Japanの最新ニュースやリリース情報をご覧ください。新製品、サービスのお知らせ、会社の最新動向など、重要なアップデートを随時公開しています。" />
      </Helmet>
      <div>
        <Header className={"fixed-header"} />
        <div className='AllSectionParticleContainer'>
          <ParticlesBackgroundTopPage />
          <section className='NewsSectionStyle'>
            <Breadcrumb
              items={[
                { label: 'ホーム', url: '/' },
                { label: 'NEWS RELEASE', url: '/newsrelease' },
              ]}
            />
            <div className='NewsTitleContainerStyle'>
              <div>
                <p className='NewsTitleENStyle'>NEWS RELEASE</p>
                <p className="NewsTitleJNStyle">ニュースリリース</p>
              </div>
            </div>
            <div className='NewsContainerStyle'>
              {
                (() => {
                  const newsItems = [];

                  for (let i = 0; i < currentProps.length; i++) {
                    const items = currentProps[i];
                    newsItems.push(
                      <a className="NewsPostLinkStyle" href={`/newsrelease/${items.id}`} key={items.id}>
                        <div className='NewsPostStyle' id={items.id} key={items.id}>
                          <p className='NewsPostDateStyle'>{items.post_date}</p>
                          <div className='NewsPostRightStyle'>
                            <p className='NewsPostTitleStyle'>{items.title}</p>
                            <ArrowCircleRightIcon style={{ color: 'black' }} />
                          </div>
                        </div>
                      </a>
                    );
                  }
                  return newsItems;
                })()
              }
            </div>
            <Pagination object={NewsReleaseObjects} pageCount={pageCount} handlePageClick={handlePageClick} />
          </section>
        </div >
        <Footer className={"home-rich-footer"} />
      </div>
    </>
  );
};