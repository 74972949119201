export const Octagon = ({ heading1, content1, pic1, heading2, content2, pic2, heading3, content3, pic3, heading4, content4, pic4 }) => {
  return (
    <div className="octagonWrapper">
      <div className="octagon1Container">
        <div className="octagon1ContentContainer">
          <h3>{heading1}</h3>
          <p className="octagon1Content">{content1}</p>
        </div>
        <div>
          <div className="octagon1">
            <div className="octagon1-inner">
              <div className="octagon1-inner-content">Step 01</div>
              <img className="octagon1-img" src={pic1} alt="hearing" />
            </div>
          </div>
        </div>
      </div>
      <div className="octagon2Container">
        <div className="octagon2">
          <div className="octagon2-inner">
            <div className="octagon2-inner-content">Step 02</div>
            <img className="octagon2-img" src={pic2} alt="estimate" />
          </div>
        </div>
        <div className="octagon2ContentContainer">
          <h3>{heading2}</h3>
          <p className="octagon2Content">{content2}</p>
        </div>
      </div>
      <div className="octagon3Container">
        <div className="octagon3ContentContainer">
          <h3>{heading3}</h3>
          <p className="octagon3Content">{content3}</p>
        </div>
        <div className="octagon3">
          <div className="octagon3-inner">
            <div className="octagon3-inner-content">Step 03</div>
            <img className="octagon3-img" src={pic3} alt="camera" />
          </div>
        </div>
      </div>
      <div className="octagon4Container">
        <div className="octagon4">
          <div className="octagon4-inner">
            <div className="octagon4-inner-content">Step 04</div>
            <img className="octagon4-img" src={pic4} alt="delivery" />
          </div>
        </div>
        <div className="octagon4ContentContainer">
          <h3>{heading4}</h3>
          <p className="octagon4Content">{content4}</p>
        </div>
      </div>


    </div>
  )
}