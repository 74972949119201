import React from 'react';
import { NewsReleaseObjects } from '../../data/NewsRelease'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export const NewsReleaseSection = () => {
  const maxLoops = 4
  const loopLimit = NewsReleaseObjects.length === 1 ? 1 : maxLoops
  return (
    <section className='NewsSectionStyle'>
      <div className='NewsTitleContainerStyle'>
        <div>
          <p className='NewsTitleENStyle'>NEWS RELEASE</p>
          <p className="NewsTitleJNStyle">ニュースリリース</p>
        </div>
      </div>
      <div className='NewsContainerStyle'>
        {
          (() => {
            const newsItems = [];
            const reversedNewsReleaseObjects = [...NewsReleaseObjects].reverse();
            for (let i = 0; i < loopLimit; i++) {
              const items = reversedNewsReleaseObjects[i];
              newsItems.push(
                <a className="NewsPostLinkStyle" href={`/newsrelease/${items.id}`} key={items.id}>
                  <div className='NewsPostStyle' id={items.id} >
                    <p className='NewsPostDateStyle'>{items.post_date}</p>
                    <div className='NewsPostRightStyle'>
                      <p className='NewsPostTitleStyle'>{items.title}</p>
                      <ArrowCircleRightIcon style={{ color: 'white' }} />
                    </div>
                  </div>
                </a>
              );
            }
            return newsItems;
          })()
        }
      </div>
      <div className='NewsButtonContainerStyle'>
        <a href="/newsrelease" className="NewsSectionMoreButtonStyle">
          <span className="NewsSectionArrow">→</span>
          <span>View More</span>
        </a>
      </div>
    </section>
  );
};