import React from 'react';
import { WorksCard } from '../../Component/WorksCard'

export const WorksSection = () => {
  return (
    <section className='WorksSectionStyle'>
      <div className='WorksTitleContainerStyle'>
        <div className='WorksTitleENBarStyle' />
        <h1 className='WorksTitleENStyle'>WORKS</h1>
      </div>
      <div className='WorksContainerStyle'>
          <WorksCard imageUrl={""} alt={"PVProduction-Pic"} title={"不動産PV制作"} content={"水戸店、成田店、千葉店で不動産業者のホームページ用PV制作をさせて頂きました。"} />
          <p className='WorksSymbolStyle'>＜</p>
          <WorksCard imageUrl={""} alt={"example2"} title={"実績２"} content={"実績内容"} />
          <p className='WorksSymbolStyle'>＞</p>
          <WorksCard imageUrl={""} alt={"example3"} title={"実績３"} content={"実績内容"} />
      </div>
    </section>
  );
};