import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { Helmet } from "react-helmet-async";
import { Breadcrumb } from '../../Component/Breadcrumb';
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';

export const Company = (() => {
  return (
    <>
      <Helmet>
        <title>会社概要 | Company Overview | 株式会社F Tech Japan</title>
        <meta name="description" content="株式会社F Tech Japanの会社概要を紹介します。" />
      </Helmet>
      <div>
        <Header className={"fixed-header"} />
        <div className='AllSectionParticleContainer'>
          <ParticlesBackgroundTopPage />
          <section className='CompanyPageStyle'>
            <Breadcrumb
              items={[
                { label: 'ホーム', url: '/' },
                { label: 'COMPANY', url: '/company' },
              ]}
            />
            <div className='CompanyTitleContainerStyle'>
              <div>
                <p className='CompanyTitleENStyle'>COMPANY</p>
                <p className="CompanyTitleJNStyle">会社概要</p>
              </div>
            </div>
            <div className='CompanyContainerStyle'>
              <dl>
                <div className='row'>
                  <dt className='companyName'>社名</dt>
                  <dd className='companyName'>株式会社 F Tech Japan</dd>
                </div>
                <div className='row'>
                  <dt>設立</dt>
                  <dd>2024年7月2日</dd>
                </div>
                <div className='row'>
                  <dt>代表取締役</dt>
                  <dd>松澤 龍弥</dd>
                </div>
                <div className='row'>
                  <dt>本社所在地</dt>
                  <dd>〒261-0026 千葉県千葉市美浜区幕張西3-6 海浜幕張コーポ1-102</dd>
                </div>
                <div className='row'>
                  <dt>事業内容</dt>
                  <dd>PV制作やWeb制作</dd>
                </div>
                <div className='row'>
                  <dt>資本金</dt>
                  <dd>300万円</dd>
                </div>
              </dl>
            </div>
            <div className='CompanyTitleContainerStyle2'>
              <div>
                <p className='CompanyTitleENStyle'>EXCECUTIVE PROFILES</p>
                <p className="CompanyTitleJNStyle">会役員紹介</p>
              </div>
            </div>
            <div className='CompanyContainerStyle'>
              <dl>
                <div className='row'>
                  <dt className='ExcectiveName'>代表取締役</dt>
                  <dd className='ExcectiveName'>松澤 龍弥</dd>
                </div>
                <div className='row'>
                  <dt>取締役</dt>
                  <dd>松澤 貴仁</dd>
                </div>
                <div className='row'>
                  <dt>取締役</dt>
                  <dd>藤村 祐路</dd>
                </div>
              </dl>
            </div>
            <div className='CompanyTitleContainerStyle3'>
              <div>
                <p className='CompanyTitleENStyle'>ACCESS</p>
                <p className="CompanyTitleJNStyle">アクセス</p>
              </div>
            </div>
            <div className='CompanyMapContainerStyle'>
              <iframe src="https://maps.google.co.jp/maps?output=embed&q=35.6629562,140.0387915" title="address" className='CompanyMapStyle'></iframe>
            </div>
          </section>
        </div >
        <Footer className={"home-rich-footer"} />
      </div>
    </>
  )
})