import React from 'react';
import { StylishSection } from '../../Component/StylishSection'
import videowebp from "../../images/video.webp"
import corporatesitewebp from "../../images/corporatesite.webp"
import youtubewebp from "../../images/youtube.webp"

export const ServicesSection = () => {
  return (
    <section className='ServicesSectionStyle'>
      <div className='ServicesTitleContainerStyle'>
        <div>
          <p className='ServicesTitleENStyle'>SERVICE</p>
          <p className="ServicesTitleJNStyle">株式会社F Tech Japanのサービスについて</p>
        </div>
      </div>
      <div className='ServicesContainerStyle'>
        <StylishSection stylishSectionLeftClassName="stylish-section-left" overlayClassName="overlay-left" imageSrc={videowebp} title="PV制作" subtitle="「最高」と思わせる仕上がりに" description="プロフェッショナルな映像制作チームが、お客様のビジョンを具現化し、魅力的な映像コンテンツを制作します。ストーリーテリングや視覚効果を駆使し、お客様のメッセージを効果的に伝えます。" />
        <StylishSection stylishSectionLeftClassName="stylish-section-right" overlayClassName="overlay-right" imageSrc={corporatesitewebp} title="コーポレートサイト制作" subtitle="「バリエーション」豊かに" description="オリジナリティ溢れるデザインとユーザーフレンドリーな機能性を兼ね備えた、魅力的なウェブサイトを提供します。ビジネスのアイデンティティを反映させ、顧客の関心を引きつけるデザインを実現します。" />
        <StylishSection stylishSectionLeftClassName="stylish-section-left" overlayClassName="overlay-left" imageSrc={youtubewebp} title="YouTube" subtitle="「宣伝力」を力に" description="YouTubeチャンネルを通じて、車関連の魅力を広めるお手伝いをします。" />
      </div>
    </section>
  );
};