import { Link } from 'react-router-dom';
export const ContactButton = ({to}) => {
  return (
    <div className='ContactButtonContainerStyle'>
      <Link to={to} className="ContactButtonStyle">
        <span className="ContactButtonArrow">→</span>
        <span>Contact</span>
      </Link>
    </div>
  )
}