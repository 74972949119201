import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { Helmet } from "react-helmet-async";
import { AboutBody } from './AboutBody';
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';

export const About = (() => {
  return (
    <>
      <Helmet>
        <title>株式会社F Tech Japanについて | About F Tech Japan, Inc. | F Tech Japanに込めた想い</title>
        <meta name="description" content="株式会社F Tech Japanは、高品質なサービスでお客様の満足を追求します。このページでは当社の理念を紹介します。" />
      </Helmet>
      <div>
        <Header className={"fixed-header"} />
        <div className="AllSectionParticleContainer">
          <ParticlesBackgroundTopPage />
          <AboutBody />
        </div>
        <Footer className={"home-rich-footer"} />
      </div>
    </>
  )
})