import React from "react";
import ScrollReveal from "scrollreveal";
import { useRef, useEffect } from "react";

export const Scroll = ({ children }) => {

  const sectionRef = useRef();
  useEffect(() => {
    if (sectionRef.current) {
      ScrollReveal().reveal(sectionRef.current, {
        reset: false,
        delay: 200,
        duration: 2000,
        opacity: 0,
        distance: "100px",
        origin: "left"
      });
    }
  },[]);

  return (<section ref={sectionRef}>{children}</section>)
}
