import React from 'react';
import { ContactButton } from '../../Component/ContactButton'

export const ContactSection = () => {
  return (
    <section className="ContactSectionStyle">
      <div className='ContactTopBorder' />
      <h1 className="ContactTitle">PVやWebサイトの制作について相談する</h1>
      <div className="ContactContainerStyle">
        <p>サービスに関してご質問がございましたら、お気軽にお問い合わせください。</p>
      </div>
      <dir className='ContactButtonContainer'>
        <ContactButton to="/contact" />
      </dir>
      <div className='ContactBottomBorder' />
    </section>
  );
};