import * as React from 'react';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';


export const ServicesCardNormal = ({title,children,jump}) => {
  return (
    <div className='ServicesCardNormalContainer'>
      <div className='ServicesCardNormalLeftRightContainer'>
      <div className='ServicesCardNormalLeftContainer'>
        <div className='ServicesCardNormalTitleContainer'>
          <div className='ServicesCardNormalTitleENBarStyle' />
          <h1 className='ServicesCardNormalTitleENStyle'>{title}</h1>
        </div>
        <p className='ServicesCardNormalContentsContainer'>{children}</p>
      </div>
      <a href={jump}><ArrowCircleDownIcon className='ArrowCircleDownIconStyle'/></a>
      </div>
    </div>
  )
}