import React from 'react';
import { MoreButton } from './MoreButton'

export const StylishSection = ({ stylishSectionLeftClassName, overlayClassName, imageSrc, title, subtitle, description }) => {
  return (
    <section className={stylishSectionLeftClassName} style={{ backgroundImage: `url(${imageSrc})` }}>
      <div className={overlayClassName}>
        <div className="text-container">
          <h1>{title}</h1>
          <p>{subtitle}</p>
          <p>{description}</p>
          <MoreButton href="/service" />
        </div>
      </div>
    </section>
  );
};
