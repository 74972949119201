import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { ContactBody } from './ContactBody';
import { Helmet } from "react-helmet-async";
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';

export const Contact = (() => {
  return (
    <>
      <Helmet>
        <title>お問い合わせ | Contact Us | 株式会社F Tech Japan</title>
        <meta name="description" content="株式会社F Tech Japanへのお問い合わせはこちらからどうぞ。製品やサービスに関するご質問やご相談は、お気軽にご連絡ください。" />
      </Helmet>
      <div>
        <Header className={"fixed-header"} />
        <div className='AllSectionParticleContainer'>
          <ParticlesBackgroundTopPage />
          <ContactBody />
        </div>
        <Footer className={"home-rich-footer"} />
      </div>
    </>
  )
})