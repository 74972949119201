import { FvSection } from '../Top/FvSection';
import { ServicesSection } from '../Top/ServicesSection';
import { AboutSection } from '../Top/AboutSection';
import { WorksSection } from '../Top/WorksSection';
import { ContactSection } from '../Top/ContactSection';
import { NewsReleaseSection } from '../Top/NewsReleaseSection';
import { CompanySection } from '../Top/CompanySection';
import { Scroll } from '../../Component/Scroll';
import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';

export const Works = (() => {
  return (
    <div>
      <Header className={"home-header"} />
      <FvSection />
      <Scroll>
        <AboutSection />
      </Scroll>
      <Scroll>
        <ServicesSection />
      </Scroll>
      <Scroll>
        <ContactSection />
      </Scroll>
      <Scroll>
        <WorksSection />
      </Scroll>
      <Scroll>
        <NewsReleaseSection />
      </Scroll>
      <Scroll>
        <CompanySection />
      </Scroll>
      <Footer className={"home-rich-footer"} />
    </div>
  )
})