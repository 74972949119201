import { ServicesCardNormal } from '../../Component/ServicesCardNormal';
import { Octagon } from '../../Component/Octagon'
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import hearingwebp from "../../images/hearing.webp"
import estimatewebp from "../../images/estimate.webp"
import camerawebp from "../../images/camera.webp"
import deliverywebp from "../../images/delivery.webp"
import pcwebp from "../../images/pc.webp"
import { Breadcrumb } from '../../Component/Breadcrumb';

export const ServicePageBody = (() => {
  return (
    <section className='ServicePageSectionStyle'>
      <Breadcrumb
        items={[
          { label: 'ホーム', url: '/' },
          { label: 'SERVICE', url: '/service' },
        ]}
      />
      <div className='ServicePageContainerStyle'>
        <div>
          <p className='ServicePageENStyle'>SERVICE</p>
          <p className="ServicePageJNStyle">サービス</p>
        </div>
      </div>
      <div>
        <div className='ServicePageCardConainer'>
          <ServicesCardNormal title="Promotion Video 制作" jump="#pv">
            対応カテゴリ：不動産、バー、スポーツクラブなど様々な領域にご対応いたします！
            <br />
            ご要望に沿いPVの企画・撮影・編集を行います。<br />プロが利用しているカメラ機材を利用し本格的に撮影いたします！
          </ServicesCardNormal>
          <ServicesCardNormal title="Corporate Site 制作" jump="#Coporate">
            対応カテゴリ：様々な領域にご対応いたします！
            <br />
            ご要望に沿いホームページなどWebサイトの制作を行います。
          </ServicesCardNormal>
          <ServicesCardNormal title="YouTube 制作" jump="#Youtube">
            対応カテゴリ：車関係の撮影にご対応いたします！
            <br />
            ご要望に沿い動画の企画・撮影・編集を行います。
            <br />
            ◆Promotion Video
            <br />
            ご自慢の車と共にご要望のPVを制作いたします！
            <br />
            ご希望の場合はYouTube「ゆーろTV」への掲載が可能
            <br />
            ◆ゆーろTVでの車紹介
            <br />
            ご自慢の車をゆーろが紹介！オーナー様の出演も可能です！
          </ServicesCardNormal>
        </div>
        <div className='ServicePageFlowTitle'>
          <h2><i className='far fa-lightbulb'><EmojiObjectsOutlinedIcon style={{ color: 'black' }} fontSize="large" /></i><span>SERVICE FLOW</span></h2>
        </div>
        <div className='ServicePageUnderContainer'>
          <h3 className='ServicePageH3Title1' id='pv'>Promotion Video</h3>
          <Octagon
            heading1="ヒアリング"
            content1="撮影・編集担当者が、お客様のご要望をお伺いして、内容・撮影日を擦り合わせます。〇〇がしたい、△△を見せたい等、なんでもお気軽にご相談ください！"
            pic1={hearingwebp}
            heading2="見積もり・合意"
            content2="撮影・編集にかかる費用のお見積書を合意いただいたら制作に入ります。"
            pic2={estimatewebp}
            heading3="撮影・編集"
            content3="プロも使用するカメラ機材で本格的に撮影致します！"
            pic3={camerawebp}
            heading4="最終確認・納品"
            content4="完成した動画の確認をしていただき、よろしければご納品となります。"
            pic4={deliverywebp}
          />
          <h3 className='ServicePageH3Title2' id='Coporate'>Coporate Site</h3>
          <Octagon
            heading1="ヒアリング"
            content1="どんなコーポレートサイトにしたいのか、イメージや掲載内容、こだわりたい点等の擦り合わせを致します。"
            pic1={hearingwebp}
            heading2="見積もり・合意"
            content2="お伺いした内容でのお見積を提示いたします。合意いただいたら制作に入ります。"
            pic2={estimatewebp}
            heading3="Webサイト構築"
            content3="工程毎に進捗を共有し、内容をご確認いただきます。出来上がっていくまでも見られて安心。"
            pic3={pcwebp}
            heading4="最終確認・納品"
            content4="完成したサイトの確認をしていただき、よろしければご納品となります。"
            pic4={deliverywebp}
          />
          <h3 className='ServicePageH3Title3' id='Youtube'>Youtube（車系動画）</h3>
          <Octagon
            heading1="ヒアリング"
            content1="PV制作またはゆーろTVでの車紹介をお選びいただきます。（両方も可）どんな動画にしたいか、出演希望の有無などをお伺いいたします。"
            pic1={hearingwebp}
            heading2="見積もり・合意"
            content2="撮影・編集にかかる費用のお見積書を合意いただいたら制作に入ります。"
            pic2={estimatewebp}
            heading3="Webサイト構築"
            content3="プロも使用するカメラ機材で本格的に撮影致します！"
            pic3={camerawebp}
            heading4="最終確認・納品"
            content4="完成したPV・紹介動画をご確認いただき、よろしければYouTubeゆーろTVにて公開いたします。※PVは、可能な方のみ公開"
            pic4={deliverywebp}
          />
          <div className='YoutubeWrapper'>
            <div className='YoutubeContainer'>
              <div className='YoutubeTopContainer'>
                <iframe className="Youtube" width="560" height="315" src="https://www.youtube.com/embed/pOXY08p-BVg?si=3qjaqjGynfYGMIdJ" title="YouTube video player" ></iframe>
              </div>
              <div className='YoutubeIntroduceContainer'>
                <div className='YoutubeIntroduceTop'>
                  <div className='YoutubeCircle' />
                  <p className='YoutubeName'>ゆーろTV</p>
                </div>
                <p className='YoutubeIntroduceBottom'>
                  こんにちはゆうろTVのゆうろと申します！<br />
                  リバティーウォーク仕様のGTRで色々な場所に行ったり皆様の車を撮影したりしてます👍<br />
                  PV撮影依頼や企業様の案件などございましたらお気軽にご連絡下さい！<br />
                  tiktok→14万フォロワー<br />
                  X→4万5千フォロワー<br />
                  youtub→93000フォロワー<br />
                  コツコツ視聴さんが増えてくれると嬉しいです😊
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})