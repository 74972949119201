import * as React from 'react';

export const WorksCard = ({ imageUrl, alt, title, content }) => {
  return (
    <div className='CardContainer'>
      <img className='CardImage' src={imageUrl} alt={alt} />
      <div className='CardTitle'>{title}</div>
      <div className='CardContent'>{content}</div>
    </div>
  )
}