import React from 'react'
import { Breadcrumb } from '../../Component/Breadcrumb';

export const AboutBody = () => {
  return (
    <section className='AboutPageStyle'>
      <Breadcrumb
        items={[
          { label: 'ホーム', url: '/' },
          { label: 'ABOUT US', url: '/aboutus' },
        ]}
      />
      <div className='AboutPageContainerStyle'>
        <div>
          <p className='AboutTitleENStyle'>ABOUT US</p>
          <p className="AboutTitleJNStyle">株式会社F Tech Japanについて</p>
        </div>
      </div>
      <div className='AboutContentsContainer'>
        <p className='AboutPageContent1'>株式会社 F Tech Japanは<br />高品質でお客様に満足していただけるようなサービスをご提供いたします。</p>
        <h1 className='AboutPageContent2'>F Tech Japanに込めた想い</h1>
        <p className='AboutPageContent3'>F Tech Japanの「F」は、様々な意味が込められております。</p>
        <p className='AboutPageContent3-2'>未来(Future)に向けて、信念(Failth)を持って、忠実(Fidelity)に、幸運(Fortune)と共に、開花(Flower)するという意味を込めて名付けました。</p>
        <p className='AboutPageContentEn4'>Future・・・未来</p>
        <p className='AboutPageContentEn6'>Faith・・・信念</p>
        <p className='AboutPageContentEn7'>Fidelity・・・忠実</p>
        <p className='AboutPageContentEn8'>Fortune・・・幸運</p>
        <p className='AboutPageContentEn5'>Flower・・・開花</p>
      </div>
    </section>
  )
}
