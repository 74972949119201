import React from 'react';
import './App.css';
import './Component/Component.css';
import '@fontsource/roboto';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Top } from '../src/Page/Top/Top';
import { About } from '../src/Page/About/About';
import { Service } from '../src//Page/Service/Service';
import { Works } from '../src/Page/Works/Works';
import { NewsRelease } from '../src/Page/NewsRelease/NewsRelease';
import { NewsReleaseSingle } from '../src/Page/NewsRelease/NewReleaseSingle';
import { Company } from '../src/Page/Company/Company';
import { Contact } from '../src/Page/Contact/Contact';
import { NotFound } from '../src/Page/NotFound/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/works" element={<Works />} />
        <Route path="/newsrelease" element={<NewsRelease />} />
        <Route path="/newsrelease/:id" element={<NewsReleaseSingle />} />
        <Route path="/company" element={<Company />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
