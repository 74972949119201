import React, { useState } from 'react';
import { init, send } from 'emailjs-com';
import { Breadcrumb } from '../../Component/Breadcrumb';

export const ContactBody = (() => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [display, setDisplay] = useState(false);
  const userID = process.env.REACT_APP_USER_ID;
  const serviceID = process.env.REACT_APP_SERVICE_ID;
  const templateID = process.env.REACT_APP_TEMPLATE_ID;
  const popupButton = () => {
    setDisplay(true)
  }
  const popupCancelButton = () => {
    setDisplay(false);
  }
  const handleClick = (e) => {
    e.preventDefault();
    sendMail();
    setDisplay(false);
  };
  const handleCancel = () => {
    setName('');
    setCompany('');
    setMail('');
    setPhone('');
    setMessage('');
    setTitle('');
  };
  const disableSend =
    name === '' || company === '' || mail === '' || phone === '' || title === '' || message === '';
  const sendMail = () => {
    if (
      userID !== undefined &&
      serviceID !== undefined &&
      templateID !== undefined
    ) {
      init(userID);

      const template_param = {
        to_name: name,
        company: company,
        from_email: mail,
        phone: phone,
        title: title,
        message: message,
      };
      send(serviceID, templateID, template_param).then(() => {
        window.alert('お問い合わせを送信致しました。');

        setName('');
        setCompany('');
        setMail('');
        setPhone('');
        setMessage('');
        setTitle('');
      });
    }
  }
  return (
    <section className='ContactPageStyle'>
      <Breadcrumb
        items={[
          { label: 'ホーム', url: '/' },
          { label: 'CONTACT', url: '/conatct' },
        ]}
      />
      <p className='ContactTopContent'>F Tech Japanにご興味をお持ちいただき、ありがとうございます。<br />
        お問い合わせは、以下フォームをご記入の上お進みください。</p>
      <p className='ContactTopContentResponsive'>F Tech Japanにご興味をお持ちいただき、<br />ありがとうございます。<br />
        お問い合わせは、<br />以下フォームをご記入の上お進みください。</p>
      <div className="mainContent">
        <div className="contentsBox">
          <form>
            <div>
              <label htmlFor="nameForm">ご氏名：</label>
            </div>
            <input
              type="text"
              id="nameForm"
              className="formInput"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div>
              <label htmlFor="companyNameForm">会社名：</label>
            </div>
            <input
              type="text"
              id="companyNameForm"
              className="formInput"
              required
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <div>
              <label htmlFor="mailForm">メールアドレス：</label>
            </div>
            <input
              type="email"
              id="mailForm"
              className="formInput"
              required
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
            <div>
              <label htmlFor="phoneForm">電話番号：</label>
            </div>
            <input
              type="text"
              id="phoneForm"
              className="formInput"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div>
              <label htmlFor="mailTitleForm">件名：</label>
            </div>
            <input
              type="text"
              id="mailTitleForm"
              className="formInput"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div>
              <label htmlFor="mailContentForm">お問い合わせ内容：</label>
            </div>
            <textarea
              type="text"
              id="mailContentForm"
              className="formInput"
              rows="5"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="btns">
              <div>
                <button
                  variant="contained"
                  color="default"
                  onClick={handleCancel}
                >
                  <strong>フォームクリア</strong>
                </button>
              </div>
              <div>
                <button
                  variant="contained"
                  color="default"
                  onClick={popupButton}
                  disabled={disableSend}
                >
                  <strong>お問い合わせを送信する</strong>
                </button>
              </div>
              <div className={display ? "PopUp" : "NonPopUp"}>
                <h2 className='popupTitle' >確認</h2>
                <p className='popupContent'>本当にこの操作を実行しますか？</p>
                <div className='popupSelect'>
                  <button id="confirmButton" onClick={handleClick}>はい</button>
                  <button id="cancelButton" onClick={popupCancelButton}>いいえ</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
})