import React from 'react';

export const AboutSection = ({ href }) => {
  return (
    <section className="AboutSectionStyle">
      <div className='AboutTitleContainerStyle'>
        <div>
          <p className="AboutTitleENStyle">ABOUT US</p>
          <p className="AboutTitleJNStyle">株式会社F Tech Japanについて</p>
        </div>
      </div>
      <div className="AboutContainerStyle">
        <h1 className='AboutContainerTitleStyle'>目指せ&emsp;世界へ</h1>
        <h1 className='AboutContainerTitleStyle'>誰とでもビジネスができる世界を</h1>
        <br />
        <br />
        <p className='AboutContainerTitleContentsStyle'>株式会社F Tech Japanは、限りない未来を見据え、世界と共に歩むために、磨き上げたITリテラシーを広げ、その恩恵をより多くの人々へ届けます。</p>
      </div>
      <div className='AboutButtonContainerStyle'>
        <a href={href} className="AboutSectionMoreButtonStyle">
          <span className="AboutSectionArrow">→</span>
          <span>View More</span>
        </a>
      </div>
    </section>
  );
};