import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Header = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  return (
    <header className={className} id='Header'>
      <a href="/"><img src="../images/header_logo.webp" className="logo" alt="株式会社 F Tech Japan" /></a>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={isOpen ? 'open' : 'close'}>
        <ul>
          <li><Link to="/aboutus" className='header_link'>ABOUT US</Link></li>
          <li><Link to="/service" className='header_link'>SERVICE</Link></li>
          <li><Link to="/works" className='header_link_disabled'>WORKS</Link></li>
          <li><Link to="/newsrelease" className='header_link'>NEWS RELEASE</Link></li>
          <li><Link to="/company" className='header_link'>COMPANY</Link></li>
          <li className='header_contact'><Link to="/contact" className='header_link'>CONTACT</Link></li>
        </ul>
      </nav>
    </header>
  )
}