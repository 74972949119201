import React from 'react';
import { Link } from 'react-router-dom';
import { ContactButton } from './ContactButton'
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer = ({ className }) => {
  return (
    <footer className={className} id='Footer'>
      <div className='footer-container'>
        <div className='top-container'>
          <div className='footer-left'>
            <img src="../images/footer_logo.webp" className="footer_logo" alt="株式会社 F Tech Japan" />
          </div>
          <div className='footer-right'>
            <nav>
              <ul>
                <li><Link to="/aboutus" className='footer_link'>ABOUT US</Link></li>
                <li><Link to="/service" className='footer_link'>SERVICE</Link></li>
                <li><Link to="/works" className='footer_link_disabled'>WORKS</Link></li>
                <li><Link to="/newsrelease" className='footer_link'>NEWS RELEASE</Link></li>
                <li><Link to="/company" className='footer_link'>COMPANY</Link></li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='center-top-container'>
          <ContactButton to="/contact" />
          <a href={"https://www.instagram.com/ftechjapan/"} aria-label="InstagramIcon" ><InstagramIcon className='instagramIcon' /></a>
        </div>
        <div className='center-bottom-container'>
          <p>株式会社 F Tech Japan</p>
          <p>〒261-0026 千葉県千葉市美浜区幕張西3-6 海浜幕張コーポ1-102</p>
        </div>
        <div className='bottom-container'>© F Tech Japan inc. All rights reserved.</div>
      </div>
    </footer>
  );
};
