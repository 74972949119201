import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = (() => {
  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <h1 style={styles.heading}>404</h1>
        <p style={styles.text}>申し訳ありません。お探しのページが見つかりませんでした。</p>
        <p>
          <Link to="/" style={styles.link}>ホームページに戻る</Link>
        </p>
      </div>
    </div>
  );
})

const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f2f2f2',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  container: {
    backgroundColor: '#fff',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '72px',
    margin: 0,
    color: '#ff6b6b',
  },
  text: {
    fontSize: '20px',
    color: '#333',
  },
  link: {
    color: '#3498db',
    textDecoration: 'none',
  },
};
