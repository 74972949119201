import React from 'react';
import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { NewsReleaseObjects } from '../../data/NewsRelease'
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { Breadcrumb } from '../../Component/Breadcrumb';
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';

export const NewsReleaseSingle = () => {
  const { id } = useParams()
  const NewsReleaseObjectsItems = NewsReleaseObjects.find(item => item.id === parseInt(id));
  return (
    <>
      <Helmet>
        <title>ニュースリリース | 最新情報とお知らせ | 株式会社F Tech Japan</title>
        <meta name="description" content="株式会社F Tech Japanの最新ニュースやリリース情報をご覧ください。新製品、サービスのお知らせ、会社の最新動向など、重要なアップデートを随時公開しています。" />
      </Helmet>
      <div>
        <Header className={"fixed-header"} />
        <div className='AllSectionParticleContainer'>
          <ParticlesBackgroundTopPage />
          <section className='NewsSectionStyle'>
            <Breadcrumb
              items={[
                { label: 'ホーム', url: '/' },
                { label: 'NEWS RELEASE', url: '/newsrelease' },
                { label: id, url: `/newsrelease/${id}` },
              ]}
            />
            <div className='NewsReleaseSingleTitleContainerStyle'>
              <div>
                <p className='NewsTitleENStyle'>NEWS RELEASE</p>
                <p className="NewsTitleJNStyle">ニュースリリース</p>
              </div>
            </div>
            <div className='NewsReleaseSingleContainerStyle'>
              <div className='NewsReleaseSinglePostStyle' id={NewsReleaseObjectsItems.id} key={NewsReleaseObjectsItems.id}>
                <p className='NewsReleaseSinglePostDateStyle'>{NewsReleaseObjectsItems.post_date}</p>
                <h1 className='NewsReleaseSinglePostTitleStyle'>{NewsReleaseObjectsItems.title}</h1>
                <div className='NewsReleaseSinglePostContentStyle'>{NewsReleaseObjectsItems.content}</div>
              </div>
            </div>
            <div className='NewsReleaseSingleButtonContainerStyle'>
              <a href="/newsrelease" className="NewsReleaseSingleNewsReleaseSingle">
                <span>View More</span>
                <span className="NewsSectionArrow">→</span>
              </a>
            </div>
          </section>
        </div >
        <Footer className={"home-rich-footer"} />
      </div>
    </>
  );
};