import React from 'react';

export const Breadcrumb = ({ items }) => {
  return (
    <nav aria-label="Breadcrumb">
      <ul className="breadcrumb">
        {items.map((item, index) => (
          <li key={index}>
            <a href={item.url}>{item.label}</a>
            {index < items.length - 1 && <span> &gt; </span>}
          </li>
        ))}
      </ul>
    </nav>
  );
};
