import React, { useState, useEffect } from 'react';

export const AutoFadeAndGrowText = ({ text, displayTime }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isGrowing, setIsGrowing] = useState(false);

  useEffect(() => {
    const display = setTimeout(() => {
      setIsVisible(true);
      const growTimeout = setTimeout(() => {
        setIsGrowing(true);
      }, 1000);
      return () => clearTimeout(growTimeout);
    }, displayTime);

    return () => clearTimeout(display);
  }, [displayTime]);

  return (
    <div className={`auto-grow-container ${isVisible ? 'visible' : ''}`}>
      <p className={`auto-grow-text ${isGrowing ? 'grow' : ''}`}>{text}</p>
    </div>
  );
};
