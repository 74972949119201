import React from 'react';

export const CompanySection = () => {
  return (
    <section className='CompanySectionStyle'>
      <div className='CompanyTitleContainerStyle'>
        <div>
          <p className="CompanyTitleENStyle">COMPANY</p>
          <p className="CompanyTitleJNStyle">会社概要</p>
        </div>
      </div>
      <dl className='CompanyContainerStyle'>
        <div className='row'>
          <dt className='companyName'>社名</dt>
          <dd className='companyName'>株式会社 F Tech Japan</dd>
        </div>
        <div className='row'>
          <dt>設立</dt>
          <dd>2024年7月2日</dd>
        </div>
        <div className='row'>
          <dt>代表取締役</dt>
          <dd>松澤 龍弥</dd>
        </div>
        <div className='row'>
          <dt>本社所在地</dt>
          <dd>〒261-0026 千葉県千葉市美浜区幕張西3-6 海浜幕張コーポ1-102</dd>
        </div>
        <div className='row'>
          <dt>事業内容</dt>
          <dd>PV制作やWeb制作</dd>
        </div>
        <div className='row'>
          <dt>資本金</dt>
          <dd>300万円</dd>
        </div>
      </dl>
    </section>
  );
};