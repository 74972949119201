import { FvSection } from './FvSection';
import { ServicesSection } from './ServicesSection';
import { AboutSection } from './AboutSection';
// import { WorksSection } from './WorksSection';
import { ContactSection } from './ContactSection';
import { NewsReleaseSection } from './NewsReleaseSection';
import { CompanySection } from './CompanySection';
import { Scroll } from '../../Component/Scroll';
import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';
import { Helmet } from "react-helmet-async";


export const Top = (() => {
  return (
    <>
      <Helmet>
        <title>トップページ | Top Page | 株式会社F Tech Japan</title>
        <meta name="description" content="株式会社F Tech Japanは、映像制作、製品開発、技術サポートなど多様なサービスを提供しています。お客様のニーズに合わせた最適なソリューションをご提案します。" />
      </Helmet>
      <div>
        <Header className={"home-header"} />
        <FvSection />
          <div className='AllSectionParticleContainer'>
            <ParticlesBackgroundTopPage />
        <Scroll>
            <AboutSection href="/aboutus" />
        </Scroll>
        <Scroll>
          <ServicesSection />
        </Scroll>
        <Scroll>
          <ContactSection />
        </Scroll>
        {/* <Scroll>
        <WorksSection />
        </Scroll> */}
        <Scroll>
          <NewsReleaseSection />
        </Scroll>
        <Scroll>
          <CompanySection />
        </Scroll>
        </div>
        <Footer className={"home-rich-footer"} />
      </div >
    </>
  )
})