import ReactPaginate from 'react-paginate';

export const Pagination = (({ pageCount, handlePageClick }) => {

  return (
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageClick}
        nextLabel="次へ >"
        pageRangeDisplayed={4}
        marginPagesDisplayed={2}
        previousLabel="< 前へ"
        renderOnZeroPageCount={null} 
        containerClassName="PaginationContainerStyle"
        pageClassName="PaginationPageStyle"
        />
  )
})