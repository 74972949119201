import React from 'react';
import { Header } from '../../Component/Header';
import { Footer } from '../../Component/Footer';
import { ServicePageBody } from './ServicePageBody';
import { Helmet } from "react-helmet-async";
import { ParticlesBackgroundTopPage } from '../../Component/ParticlesBackgroundTopPage';

export const Service = (() => {
  return (
    <>
      <Helmet>
        <title>サービス内容 | Services Overview | 株式会社F Tech Japan</title>
        <meta name="description" content="株式会社F Tech Japanは、映像制作、製品開発、技術サポートなど多様なサービスを提供しています。お客様のニーズに合わせた最適なソリューションをご提案します。" />
      </Helmet>
      <div>
        <Header className={"fixed-header"} />
        <div className='AllSectionParticleContainer'>
          <ParticlesBackgroundTopPage />
          <ServicePageBody />
        </div >
        <Footer className={"home-rich-footer"} />
      </div>
    </>
  )
})